<template>
  <div class="menu">
    <router-link v-if="checkPermission('A&VTH')" to="/">
      Tableau de bord
    </router-link>

    <router-link v-if="checkPermission('A&VTH')" to="/rapportTh">
      Analyse et validation
    </router-link>
    <router-link
      v-if="checkPermission('FOTH') && checkPermission('GAPTH')"
      :to="
        isSuperAdminGetter
          ? '/obligee/gestion-appels-paiement-obligee'
          : '/obligee'
      "
      :class="{
        'router-link-exact-active': ComputedRouterLinkObligeeActive,
        a: !ComputedRouterLinkObligeeActive
      }"
    >
      Obligés
    </router-link>
    <MenuFactures v-if="computedShowFactureMenu" :model="model"></MenuFactures>

    <router-link to="/comptabilite" v-if="checkPermission('COTH')">
      Comptabilité
    </router-link>

    <router-link to="/reglement-facture" v-if="checkPermission('RFTH')">
      Règlement Factures
      <span class="custom-badge-cdg" title="paiement non lettré">{{
        getBadgeReglements
      }}</span>
    </router-link>
    <router-link to="/refresh-fature" v-if="checkPermission('REFFTH')">
      Refresh factures
    </router-link>
  </div>
</template>

<script>
import MenuFactures from './MenuFactures.vue';
import { mapGetters } from 'vuex';
export default {
  data() {
    return {
      openMenu: true,
      model: 0
    };
  },
  components: {
    MenuFactures
  },
  methods: {
    openMenuFacture() {
      // if (this.openMenu != true) {
      //   this.openMenu = true;
      // }
    }
  },
  computed: {
    ...mapGetters([
      'getUserData',
      'checkPermission',
      'isSuperAdminGetter',
      'isAdminRegie',
      'getBadgeReglements',
      'computedRouterLinkFacture'
    ]),
    itemsFactures() {
      return [
        {
          name: 'Gestion des frais',
          href: this.computedRouterFrais,
          code: ['GDFTH', 'SFTH', 'GGDFTH'],
          selected: false,
          children: [
            {
              name: 'Simulation Frais ',
              href: '/frais-th/simulation-facture-frais'
            },
            {
              name: 'Gestion global des frais ',
              href: '/frais-th/vision-globale-frais'
            }
          ]
        },
        {
          name: 'Factures libres',
          href: '/facture-libre',
          code: 'FL',
          children: []
        },
        {
          name: 'Factures SCI',
          href: '/facture-sci',
          code: 'FSTH',
          children: []
        },
        {
          name: 'Gestion factures mensuelles',
          href: '/gestion-facture-mensuel',
          code: 'GFMTH',
          children: []
        },
        {
          name: 'Gestion master filliale',
          href: '/master-filiale-2',
          code: 'GMFTH',
          children: []
        },
        {
          name: 'Factures commerciaux',
          href: this.computedFacturesCommerciaux,
          code: ['FC', 'GAPC'],
          selected: false,
          children: [
            {
              name: 'Appel à paiement ',
              href: '/commercial/appel-paiement'
            }
          ]
        },
        {
          name: 'Factures Pixel',
          href: '/pixel-facture',
          code: 'GFPXTH',
          children: []
        }
      ];
    },
    computedShowFactureMenu() {
      return (
        this.checkPermission('FL') ||
        this.checkPermission('FSTH') ||
        this.checkPermission('GMFTH') ||
        this.checkPermission('GFMTH') ||
        this.ComputedRouterLinkFraisThShow
      );
    },
    ComputedRouterLinkObligeeActive: function() {
      return this.$route.name === 'gestionAppelPaiementObligee';
    },
    ComputedRouterLinkFraisThShow: function() {
      return (
        this.checkPermission('GDFTH') &&
        (this.checkPermission('SFTH') || this.checkPermission('GGDFTH'))
      );
    },
    computedFacturesCommerciaux: function() {
      if (this.checkPermission('GAPC')) {
        return '/commercial/appel-paiement';
      }
      return '/commercial';
    },
    computedRouterFrais: function() {
      if (this.checkPermission('SFTH')) {
        return '/frais-th/simulation-facture-frais';
      }
      if (this.checkPermission('GGDFTH')) {
        return '/frais-th/vision-globale-frais';
      }
      return '/frais-th';
    }
  },
  watch: {
    $route(e) {
      if (this.computedRouterLinkFacture) {
        // this.openMenu = true;
        this.model = e.meta.tab;
      } else {
        // this.openMenu = false;
      }
    }
  },
  mounted() {
    if (this.computedRouterLinkFacture) {
      // this.openMenu = true;
      this.model = this.$route.meta.tab;
    } else {
      // this.openMenu = false;
    }
  }
};
</script>

<style lang="scss" scoped>
.menu > .Search-style-component {
  margin: auto !important;
}
.menu {
  display: flex;
  align-items: center;
  margin-left: 10px;
  height: 100%;
  a {
    display: block;
    overflow: hidden;
    font-weight: 600;
    padding: 13px 8px;
    font-size: 11px;
    line-height: 14.07px;
    white-space: nowrap;
    text-overflow: ellipsis;
    text-decoration: none;
    color: #2f2f2f;
    margin-right: 4px;
    font-family: 'Montserrat', sans-serif;
    border-bottom: 1px solid transparent;
    // height: 100%;

    &.router-link-exact-active {
      // padding: 13px 8px;
      color: #704ad1;
      border-bottom: 1px solid #704ad1;
    }
    &:hover {
      // padding: 13px 8px;
      color: #704ad1;
      border-bottom: 1px solid #704ad1;
    }
  }
  .font-tabs-facture {
    font-size: 14px;
    line-height: 24px;
    font-family: 'Montserrat', sans-serif;
    font-weight: 600px;
  }
}
@media screen and (max-width: 1200px) {
  .menu {
    display: inline;
    a {
      margin: 0px 5px;
    }
  }
}
</style>
<style lang="scss">
.btn-secondary:not(:disabled):not(.disabled):active,
.btn-secondary:not(:disabled):not(.disabled).active,
.show > .btn-secondary.dropdown-toggle {
  background-color: #704ad1;
  height: 31px;
}
</style>
