<template>
  <div class="sub-menu-facture-cdg">
    <!-- MENU TABULATION FACTURE  -->
    <v-menu
      transition="slide-y-transition"
      content-class="my-menu"
      :close-on-content-click="computedCloseOnClick"
      :open-on-hover="true"
    >
      <!-- TITLE MENU  -->
      <template v-slot:activator="{ on }">
        <div
          v-on="on"
          :class="{
            'router-link-exact-active': computedRouterLinkFacture
          }"
          class=" text-center title-principale"
        >
          Factures
          <font-awesome-icon
            :icon="['fat', 'chevron-down']"
            class="chevron-down-icon "
          />
        </div>
      </template>
      <!-- TITLE MENU  -->
      <v-list>
        <v-list-item
          v-for="(item, i) in itemsFactures"
          :key="i"
          class="list-item-fact"
        >
          <!-- SIMPEL ITEM -->
          <router-link
            :to="item.href"
            class="title-fact-menu text-start"
            v-if="item.children.length == 0"
            @click.native="closeOnClick = true"
          >
            {{ item.name }}
          </router-link>
          <!-- END SIMPEL ITEM -->

          <!-- ITEM WITH CHILD : -->
          <div v-else>
            <div
              :class="{
                'router-link-exact-active': computedActiveSubMenu(item.children)
              }"
              class="title-fact-menu text-start "
              @click.prevent.stop="
                closeOnClick = false;
                item.selected = !item.selected;
              "
            >
              {{ item.name }}
              <font-awesome-icon
                :icon="
                  item.selected
                    ? ['fat', 'chevron-up']
                    : ['fat', 'chevron-down']
                "
                class="chevron-down-icon "
              />
            </div>
            <div v-if="item.selected" class="pl-2 transition-sub-menu ">
              <router-link
                v-for="(itemC, i) in item.children"
                :key="i"
                :to="itemC.href"
                class="font-tabs-facture title-fact-menu"
                @click.native="
                  closeOnClick = true;
                  item.selected = false;
                "
              >
                <font-awesome-icon
                  :icon="['fad', 'arrow-turn-down-right']"
                  class="chevron-down-icon"
                />
                {{ itemC.name }}
              </router-link>
            </div>
          </div>
          <!-- END ITEM WITH CHILD : -->
        </v-list-item>
      </v-list>
    </v-menu>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
export default {
  props: {
    model: {
      required: false
    }
  },
  data() {
    return {
      tabValue: this.model,
      closeOnClick: true,
      itemsFactures: [
        {
          name: 'Gestion des frais',
          // href: this.computedRouterFrais,
          code: ['GDFTH', 'SFTH', 'GGDFTH'],
          selected: false,
          children: [
            {
              name: 'Simulation Frais ',
              href: '/frais-th/simulation-facture-frais'
            },
            {
              name: 'Gestion global des frais ',
              href: '/frais-th/vision-globale-frais'
            }
          ]
        },
        {
          name: 'Factures libres',
          href: '/facture-libre',
          code: 'FL',
          children: []
        },
        {
          name: 'Factures SCI',
          href: '/facture-sci',
          code: 'FSTH',
          children: []
        },
        {
          name: 'Gestion factures mensuelles',
          href: '/gestion-facture-mensuel',
          code: 'GFMTH',
          children: []
        },
        {
          name: 'Gestion master filliale',
          href: '/master-filiale-2',
          code: 'GMFTH',
          children: []
        },
        {
          name: 'Factures commerciaux',
          code: ['FC', 'GAPC'],
          selected: false,
          children: [
            {
              name: 'Appel à paiement ',
              href: '/commercial/appel-paiement'
            }
          ]
        },
        {
          name: 'Factures Pixel',
          href: '/pixel-facture',
          code: 'GFPXTH',
          children: []
        }
      ]
    };
  },
  watch: {
    $route(e) {
      if (
        e.name == 'facture-libre' ||
        e.name == 'facture-sci' ||
        e.name == 'simulationFactureFrais' ||
        e.name == 'gestion-facture-mensuel' ||
        e.name == 'masterFiliale-2' ||
        e.name == 'appel-paiement' ||
        e.name == 'visionGlobaleFrais' ||
        e.hash == '#'
      ) {
        if (
          e.name == 'simulationFactureFrais' ||
          e.name == 'visionGlobaleFrais'
        ) {
          this.tabValue = 'tab-0';
        } else {
          this.tabValue = e.meta.tab;
        }
      }
    }
  },
  computed: {
    ...mapGetters(['computedRouterLinkFacture', 'checkPermission']),
    computedCoesCheck() {
      return function(coeds) {
        if (typeof coeds == 'string') {
          return this.checkPermission(coeds);
        } else {
          let data = false;
          coeds.forEach(element => {
            if (this.checkPermission(element)) {
              data = true;
            }
          });
          return data;
        }
      };
    },
    computedCloseOnClick() {
      return this.closeOnClick;
    },
    computedActiveSubMenu() {
      return function(item) {
        let urls = item.map(i => i.href);
        return urls.includes(this.$route.path);
      };
    }
  },
  methods: {},
  mounted() {
    if (this.computedRouterLinkFacture) {
      if (
        this.$route.name == 'simulationFactureFrais' ||
        this.$route.name == 'visionGlobaleFrais'
      ) {
        this.tabValue = 'tab-0';
      } else {
        this.tabValue = this.$route.meta.tab;
      }
    }
  }
};
</script>
<style scoped lang="scss">
.my-menu {
  margin-top: 37px;
  contain: initial;
  overflow: visible;
  box-shadow: 1px 0px 20px rgba(0, 0, 0, 0.08);
  min-width: 218px;
  .v-list.v-sheet.theme--light {
    padding-top: 10px !important;
    padding-bottom: 10px !important;
  }
}
.my-menu::before {
  position: absolute;
  content: '';
  top: 0;
  left: 63px;
  transform: translateY(-100%);
  width: 10px;
  height: 13px;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 13px solid #fff;
}
.transition-sub-menu {
}
.sub-menu-facture-cd {
  height: 100%;
}
.list-item-fact {
  min-height: 32px !important;
}
.title-principale {
  display: block;
  overflow: hidden;
  font-weight: 600;
  padding: 13px 8px;
  font-size: 11px;
  line-height: 14.07px;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-decoration: none;
  color: #2f2f2f;
  margin-right: 4px;
  font-family: 'Montserrat', sans-serif;
  border-bottom: 1px solid transparent;
  // height: 100%;

  &.router-link-exact-active {
    // padding: 13px 8px;
    color: #704ad1;
    border-bottom: 1px solid #704ad1;
  }
  &:hover {
    // padding: 13px 8px;
    color: #704ad1;
    border-bottom: 1px solid #704ad1;
  }
}
.title-fact-menu {
  display: block;
  overflow: hidden;
  font-weight: 600;
  font-size: 11px;
  line-height: 14.07px;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-decoration: none;
  color: #2f2f2f;
  border-radius: 25px;
  margin-right: 4px;
  font-family: 'Montserrat', sans-serif;
  padding: 4px 5px;
  // text-align: center;
  text-transform: capitalize;
  cursor: pointer;
  &:hover {
    // .chevron-down-icon {
    //   display: none !important;
    // }
    color: #704ad1;
  }
  &.router-link-exact-active {
    color: #704ad1;
    font-weight: 700;
  }
}
.chevron-down-icon {
  height: 10px;
  margin-left: 4px;
}
</style>
