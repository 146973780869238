var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"sub-menu-facture-cdg"},[_c('v-menu',{attrs:{"transition":"slide-y-transition","content-class":"my-menu","close-on-content-click":_vm.computedCloseOnClick,"open-on-hover":true},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('div',_vm._g({staticClass:"text-center title-principale",class:{
          'router-link-exact-active': _vm.computedRouterLinkFacture
        }},on),[_vm._v(" Factures "),_c('font-awesome-icon',{staticClass:"chevron-down-icon",attrs:{"icon":['fat', 'chevron-down']}})],1)]}}])},[_c('v-list',_vm._l((_vm.itemsFactures),function(item,i){return _c('v-list-item',{key:i,staticClass:"list-item-fact"},[(item.children.length == 0)?_c('router-link',{staticClass:"title-fact-menu text-start",attrs:{"to":item.href},nativeOn:{"click":function($event){_vm.closeOnClick = true}}},[_vm._v(" "+_vm._s(item.name)+" ")]):_c('div',[_c('div',{staticClass:"title-fact-menu text-start",class:{
              'router-link-exact-active': _vm.computedActiveSubMenu(item.children)
            },on:{"click":function($event){$event.preventDefault();$event.stopPropagation();_vm.closeOnClick = false;
              item.selected = !item.selected;}}},[_vm._v(" "+_vm._s(item.name)+" "),_c('font-awesome-icon',{staticClass:"chevron-down-icon",attrs:{"icon":item.selected
                  ? ['fat', 'chevron-up']
                  : ['fat', 'chevron-down']}})],1),(item.selected)?_c('div',{staticClass:"pl-2 transition-sub-menu"},_vm._l((item.children),function(itemC,i){return _c('router-link',{key:i,staticClass:"font-tabs-facture title-fact-menu",attrs:{"to":itemC.href},nativeOn:{"click":function($event){_vm.closeOnClick = true;
                item.selected = false;}}},[_c('font-awesome-icon',{staticClass:"chevron-down-icon",attrs:{"icon":['fad', 'arrow-turn-down-right']}}),_vm._v(" "+_vm._s(itemC.name)+" ")],1)}),1):_vm._e()])],1)}),1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }